import React from "react"
import styled from "styled-components"
import { Colors } from "../colors/Colors"
import Layout from "../components/layout"
import MainButton from "../components/main-button"
import PageHeader from "../components/page-header"
import SEO from "../components/seo"

const MainContainer = styled.div`
  padding: 8rem 20px;
  max-width: 800px;
  margin: 0 auto;
  font-family: "Articulat";
  display: flex;
  flex-direction: column;
  color: #404040;

  a {
    font-weight: 500;
    color: ${Colors.gold};
  }
`

export default function CompetitionEntry() {
  return (
    <Layout>
      <SEO title="Win a Phillips Sonicare diamond clean and free cleans for a year" />
      <MainContainer>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            style={{ textAlign: "center" }}
            src={require("../images/sonicare2.jpg")}
          />
        </div>
        <h1>The Competition</h1>
        <p>
          To Qualify to win a philips Sonicare diamond clean 9400 electric
          toothbrush and free <b>dental</b> cleanings for a year
        </p>
        <p style={{ fontStyle: "italic" }}>
          Winners will be selected at random on May 31st.
        </p>
        <h2>How to enter</h2>
        <ol>
          <li>
            Like and follow on{" "}
            <a
              target="_blank"
              rel="no referrer"
              href="https://www.facebook.com/SmileInnTT"
            >
              Facebook
            </a>
          </li>
          <li>
            Follow us on{" "}
            <a
              target="_blank"
              rel="no referrer"
              href="https://www.instagram.com/smileinntt/"
            >
              Instagram
            </a>{" "}
            and like your favourite post
          </li>
          <li>
            <a
              target="_blank"
              rel="no referrer"
              href="https://g.page/r/CdsiAQ5uR_paEAg/review"
            >
              Leave us a Google review
            </a>{" "}
            about your experience at Smile Inn Dental
          </li>
        </ol>
        <a
          href="https://forms.gle/xLqPxy8Kjn2orG6u6"
          target="_blank"
          rel="no referrer"
          style={{ display: "inline-block", width: "100%" }}
        >
          <MainButton style={{ width: "100%" }}>Submit your entry</MainButton>
        </a>
      </MainContainer>
    </Layout>
  )
}
